<template>
  <div
    class="mt-4 flex w-full flex-col gap-2 self-start rounded bg-white p-6 drop-shadow-lg sm:max-w-[481px] lg:self-end lg:p-10"
  >
    <h1
      class="text-teal-primary text-2xl font-semibold tracking-wide md:text-3xl lg:text-[32px]"
    >
      Unlock Your Benefits – Get FREE Glasses Today!
    </h1>
    <h3 class="text-grays-dark">
      Sign in to discover your eligible benefits and see if you or your
      dependents can claim free eyewear.
    </h3>
    <ZnButton
      type="primary"
      class="mt-4"
      @click="signInStore.setShowSignIn(true)"
      >Check benefits now</ZnButton
    >
  </div>
</template>
<script setup lang="ts">
import { useSignInStore } from '~/store/signin'
const signInStore = useSignInStore()
</script>
