<template>
  <div class="bg-teal-lightest h-[499px] w-full lg:h-[390px]">
    <section
      class="bg-grays-lighter relative m-auto flex h-full w-full max-w-screen-xl items-end bg-[url('/img/landing/vision/hero-vision-mobile.jpeg')] bg-cover bg-bottom bg-no-repeat px-5 pb-14 md:bg-[url('/img/landing/hero-vision.png')] md:bg-top lg:px-20"
    >
      <div
        v-if="!isGuestMode"
        class="mt-4 flex w-full flex-col gap-2 self-start rounded bg-white p-6 drop-shadow-lg sm:max-w-sm lg:self-end lg:p-10"
      >
        <div class="flex flex-col">
          <h1
            class="text-teal-primary text-2xl font-semibold tracking-wide md:text-3xl lg:text-[32px]"
          >
            Stylish Eyewear for Everyone
          </h1>
        </div>
        <h3 class="text-grays-dark">
          Now Available in Prescription and Non-Prescription Options
        </h3>
      </div>
      <LandingGuestHeroCard v-else />
      <span class="absolute bottom-2 right-2 text-sm text-white"
        >Style no. 2020123, 195421</span
      >
    </section>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useGuestStore } from '~/store/guest'
const { isGuestMode } = storeToRefs(useGuestStore())
</script>
