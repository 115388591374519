<template>
  <div class="mx-auto flex w-full flex-col justify-center">
    <LandingSafetyHero v-if="!isAdminGift" />
    <LandingHighLights v-if="!isAdminGift" />
    <div class="mx-auto flex w-full max-w-7xl flex-col gap-10 pt-10">
      <section
        class="grid grid-cols-1 justify-items-center gap-6 px-4 min-[360px]:grid-cols-2 min-[360px]:gap-10 lg:grid-cols-4"
      >
        <nuxt-link v-for="cat in categories" :key="cat.to" :to="cat.to">
          <ProductCategoryCard :title="cat.title">
            <template #image>
              <img
                class="w-[150px] md:w-[250px]"
                :src="cat.path"
                :alt="cat.alt"
              />
            </template>
          </ProductCategoryCard>
        </nuxt-link>
      </section>
      <LandingPopular class="px-4 xl:px-0" />
      <LandingUnderBudget class="px-4 xl:px-0" />
      <LandingSafetyGlasses class="px-4 xl:px-0" />
      <LandingSafetyHighlights class="px-4 lg:pt-6 xl:px-0" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useGuestStore } from '~/store/guest'

const { isAdminGift } = storeToRefs(useGuestStore())
const categories = [
  {
    to: '/products/list/prescription',
    path: '/img/landing/safety/category-rx.png',
    title: 'Prescription',
    alt: 'Black safety glasses.',
  },
  {
    to: '/products/list/non-rx',
    path: '/img/landing/safety/category-nonrx.png',
    title: 'Non-Prescription',
    alt: 'Three pairs of non-prescription eyeglasses in different styles.',
  },
  {
    to: '/products/list/progressive',
    path: '/img/landing/safety/category-progressives.png',
    title: 'Progressives',
    alt: 'Safety glasses with progressive lenses and nose pads.',
  },
  {
    to: '/products/list/highprescription',
    path: '/img/landing/safety/category-highprescription.png',
    title: 'High Prescription',
    alt: 'Safety glasses with high prescription support.',
  },
]
</script>
