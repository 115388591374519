<template>
  <div class="bg-grays-lighter h-[499px] w-full lg:h-[390px]">
    <section
      class="bg-grays-lighter m-auto flex h-full w-full max-w-[1280px] items-end rounded-md bg-[url('/img/landing/mobile-full.png')] bg-cover bg-[center_top_-4rem] bg-no-repeat px-5 pb-4 md:bg-[center_top_-12rem] lg:bg-[url('/img/landing/header-transparent.png')] lg:bg-bottom lg:px-20 lg:pb-14"
    >
      <div
        v-if="!isGuestMode"
        class="mt-auto flex w-full flex-col gap-2 rounded bg-white p-6 drop-shadow-lg md:w-fit lg:p-10"
      >
        <span v-if="primaryUser" class="text-grays-dark font-semibold"
          >Hi {{ primaryUser.firstName }},</span
        >
        <div class="flex flex-col">
          <template v-if="currentGiftAmt">
            <h1
              class="text-teal-primary text-2xl font-semibold tracking-wide md:text-3xl"
            >
              Claim your FREE safety glasses today
            </h1>
            <h2 class="text-grays-darkest hidden pt-1 font-bold md:block">
              Up to {{ formattedBudget }} value
            </h2>
          </template>
          <h1
            v-else
            class="text-teal-primary text-2xl font-semibold tracking-wide md:text-3xl"
          >
            Claim your safety glasses today
          </h1>
        </div>
        <h3 class="text-grays-dark text-sm">
          Available with prescription and non-prescription lens.
        </h3>
      </div>
      <LandingGuestHeroCard v-else class="mb-4 self-end md:mb-0 lg:self-auto" />
    </section>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { formatCurrency } from '@/utils/helper/formatCurrency'
import { useGuestStore } from '~/store/guest'

const guestStore = useGuestStore()
const { primaryUser, currentGift, isGuestMode } = storeToRefs(guestStore)

const currentGiftAmt = computed<number>(() =>
  currentGift.value ? guestStore.calculateGiftAmt(currentGift.value) : 0,
)

const formattedBudget = computed<string>(() =>
  formatCurrency(currentGiftAmt.value, 'en-US', {
    currency: 'USD',
    minimumFractionDigits: 0,
  }),
)
</script>
